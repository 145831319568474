import {
    Children,
    Fragment,
    PropsWithChildren,
} from 'react';
// import { SlideProps } from 'react-slideshow-image';
import dynamic from 'next/dynamic';
import {
    isMobile,
    isTabletPortrait,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import { Settings } from 'react-slick';
import styles from './HistorySlider.module.scss';
import OffscreenLoader from '@app/ui/OffscreenLoader/OffscreenLoader';
import ArrowRight from '@app/icons/slider-chevron-right.svg';
import Image from '@app/components/Image/Image';

import './slider.scss';

const LazySlide = dynamic(() => import('react-slick').then(module => module), { ssr: false });

export interface IProps extends PropsWithChildren {
    /**
     * Флаг для частичного отображения следующего слайда
     */
    showNextSlide?: boolean;
}

const RESPONSIVE_SETTINGS = [
    {
        breakpoint: 568,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        },
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        },
    },
    {
        breakpoint: 1024,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
        },
    },
];

/**
 * Компонент слайдера истории компании
 */
export default function HistorySlider({
    showNextSlide,
    children,
}: IProps) {
    const { width: windowWidth } = useWindowSize();
    const isMobileView = isMobile(windowWidth);
    const isTabletPortraitView = isTabletPortrait(windowWidth);

    const PrevArrow = (
        <div
            className={buildClassNames(styles, ['arrow-wrapper', 'arrow-wrapper--left'])}
        >
            <Image src={ArrowRight} alt="" width={15} height={15} />
        </div>
    );

    const NextArrow = (
        <div
            className={buildClassNames(styles, ['arrow-wrapper', 'arrow-wrapper--right'])}
        >
            <Image src={ArrowRight} alt="" width={15} height={15} />
        </div>
    );

    const SLIDER_SETTINGS: Settings = {
        children,
        // Стрелки навигации отображаются только на горизонтальном планшетном разрешении
        arrows: !isMobileView && !isTabletPortraitView,
        dots: false,
        autoplay: false,
        infinite: false,
        className: styles['slider'],
        speed: 1200,
        draggable: true,
        swipe: true,
        responsive: RESPONSIVE_SETTINGS,
        nextArrow: NextArrow,
        prevArrow: PrevArrow,
    };

    return (
        <OffscreenLoader>
            {({
                ref,
                inView,
            }) => (
                <div ref={ref} className={styles['container']}>
                    <div className={showNextSlide && styles['slim']}>
                        {inView && (
                            <LazySlide {...SLIDER_SETTINGS} infinite={false}>
                                {Children.map(children, (child, i) => (
                                    <Fragment key={i}>
                                        <div className={styles['item-wrapper']}>{child}</div>
                                    </Fragment>
                                ))}
                            </LazySlide>
                        )}
                    </div>
                </div>
            )}
        </OffscreenLoader>
    );
}

