import { CURRENCY_RUB } from '@frontend/jetlend-core/src/formatters/formatUtils';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import { useMemo } from 'react';
import styles from './HistoryFeature.module.scss';
import HistorySlider from './HistorySlider/HistorySlider';
import HistoryEvent from './HistoryEvent/HistoryEvent';
import { FeaturesSectionType } from '@app/models/sections/features';

const EVENT_INDEXES_WITH_CURRENCY_POSTFIX = [1, 3];

const splitPhraseToWords = (phrase: string) => {
    const words = phrase.split(' ');
    return words.map((word, index, array) => (
        <span key={index} className={buildClassNames(styles, ['value', word === CURRENCY_RUB && 'value--postfix'])}>
            {word}
            {index !== array.length - 1 && ' '}
        </span>
    ));
};

export default function HistoryFeature({ section }: FeaturesSectionType) {
    const blocks = useMemo(() => {
        const length = section?.blocks.length;
        if (!length) {
            return [];
        }
        const middle = Math.ceil(length / 2);

        // Разбиваем массив на две части и переворачиваем вторую часть (для корректного отображения на десктопе)
        return [...section?.blocks?.slice(0, middle), ...section?.blocks?.slice(middle, length).reverse()];

    }, [ section ]);

    return (
        <div className={styles['feature']}>
            <h2 className={styles['feature-title']}>{section.title}</h2>
            <div className={styles['history']}>
                {blocks.map((block, index, array) => (
                    <HistoryEvent
                        key={block.id}
                        year={block.value}
                        title={EVENT_INDEXES_WITH_CURRENCY_POSTFIX.includes(index) ? splitPhraseToWords(block.title) : block.title}
                        titleDescription={block.value_description}
                        description={block.description}
                        isLast={index === Math.ceil(array.length / 2)}
                        className={buildClassNames(styles, ['history-event', index >= Math.ceil(array.length / 2) && 'history-event--second-row'])}
                    />
                ))}
                <div className={styles['history-line']}>
                    <div className={styles['line-tale']} />
                </div>
            </div>
            <div className={styles['slider-wrapper']}>
                <div className={styles['slider']}>
                    <HistorySlider showNextSlide>
                        {section.blocks.map((block, index, array) => (
                            <HistoryEvent
                                key={block.id}
                                year={block.value}
                                title={EVENT_INDEXES_WITH_CURRENCY_POSTFIX.includes(index) ? splitPhraseToWords(block.title) : block.title}
                                titleDescription={block.value_description}
                                description={block.description}
                                isLast={index === array.length - 1}
                            />
                        ))}
                    </HistorySlider>
                </div>
            </div>
        </div>
    );
}
